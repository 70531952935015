import React from 'react'
import { SERVICES } from '../../constants/ComapnyInfo'
import { Link } from 'react-router-dom'
import ServiceItem from './ServiceItem'

export default function HomeOneService() {
    return (
        <>
            {/* <!-- service section start --> */}
            <section className="service-area pt-75 pb-75">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 text-center">
                            <div className="section-header mb-75">
                                <h4 className="sub-heading mb-25">
                                    <span><img src="/images/shape/heading-shape-1.png" className="mr-10" alt="" /></span>
                                    Our Services
                                    <span><img src="/images/shape/heading-shape-2.png" className="ml-10" alt="" /></span>
                                </h4>
                                <h2 className="section-title">Top Services We Offer</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-none-50">
                        {
                            SERVICES.slice(0, 4).map((item, index) => (
                                <ServiceItem item={item} key={index} />
                            ))
                        }



                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-8 text-center">
                            <div className="view-all mt-55">
                                <p>Explore our services. <Link to="services">All Services</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- service section end -->    */}
        </>
    )
}
