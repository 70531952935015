import React from 'react'
import Header1 from './Header1'
import Footer from './Footer'
import { Outlet } from "react-router-dom";
import LoadingSpinner from './LoadingSpinner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Layout1() {
    return (
        <>
        <LoadingSpinner/>
        <ToastContainer />
           <Header1/>
           <Outlet/>
           <Footer/>
        </>
    )
}
