import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import { PARTNERS } from '../../constants/ComapnyInfo';

//SwiperCore.use([Navigation, Pagination]);


export default function HomeOneBrand() {
    return (
        <>
            {/* <!-- brand section start --> */}
            <div className="brand-section pt-125 pb-120">
                <div className="container">
                    <div className="row bg-white">
                        <div className="col-xl-12">
                            <div className="brand-carousel owl-carousel">
                                <Swiper
                                    modules={[Autoplay, Navigation]}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}

                                    navigation={true}
                                    spaceBetween={2}
                                    slidesPerView={2}
                                    autoplaydisableoninteraction={"false"}

                                    breakpoints={{

                                        // when window width is >= 768px
                                        576: {
                                            slidesPerView: 2,
                                        },
                                        768: {
                                            // when window width is >= 992px
                                            slidesPerView: 3,
                                        },
                                        992: {
                                            // when window width is >= 992px
                                            slidesPerView: 4,
                                        },
                                        1024: {
                                            // when window width is >= 992px
                                            slidesPerView: 4,
                                        }
                                    }}

                                >
                                    {
                                        PARTNERS.map((item, index) => (
                                            <SwiperSlide key={index}>
                                                <div className="brand-carousel__item">
                                                    <img className="default" src={item.imgPath} alt={item.title} />
                                                    <img className="hover" src={item.imgPath} alt={item.title} />
                                                </div>
                                            </SwiperSlide>
                                        ))
                                    }


                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- brand section end -->    */}
        </>
    )
}
