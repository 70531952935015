import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import Typewriter from 'typewriter-effect';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import HeroForm from './HeroForm';
import { COMAPNY_NAME } from '../../constants/ComapnyInfo';

export default function HomeOneHero() {
    const team = [        
        {
            title: ["Welcome", "To"],
            desc: COMAPNY_NAME,
            // img: (
            //     <Image
            //         src="/images/shape/hero-shape.png"
            //         layout="responsive"
            //         width={1174}
            //         height={899}
            //     />
            // ),
            src: "/images/banner/banner.jpeg"
        }
    ];

    return (
        <>
            {/* <!-- hero start --> */}
            <section className="hero position-relative mb-100">
                <Swiper
                    // install Swiper modules
                    modules={[Autoplay, Navigation]}
                    autoplay={false}
                    spaceBetween={20}
                    slidesPerView={1}
                    autoplaydisableoninteraction={"false"}
                    loop={true}
                    className=""
                    navigation={{
                        prevEl: ".custom-prev",
                        nextEl: ".custom-next",
                    }}
                // pagination={{ clickable: true }}
                // breakpoints={{

                //     // when window width is >= 768px
                //     768: {
                //         slidesPerView: 2,
                //     },
                //     992: {
                //         // when window width is >= 992px
                //         slidesPerView: 3,
                //     }
                // }}
                // scrollbar={{ draggable: true }}
                // onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={() => console.log('slide change')}
                >
                    {team.map((item, i) => (
                        <SwiperSlide key={i}>
                            <div className="hero__item">
                                <div className="container-fluid p-0">
                                    <div className="row no-gutters">
                                        <div className="col-xl-6 col-lg-7">
                                            <div className="hero__content">
                                                <h2 className="hero__title" data-animation="fadeIn" data-delay=".2s" data-duration=".5s">{item.title[0]} <br />
                                                    {item.title[1]}</h2>
                                                {/* <p data-animation="fadeInUp" data-delay=".5s" data-duration=".7s">{item.desc}</p> */}
                                                
                                                <Typewriter
                                                    options={{
                                                        strings: [item.desc],
                                                        autoStart: true,
                                                        loop: true,
                                                    }}
                                                />
                                            </div>
                                        </div>                         
                                    </div>
                                </div>
                                <div className="hero__image d-flex align-self-stretch">
                                    <img src={item.src} alt="" />
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}

                </Swiper>
                <div className="owl-nav">
                    <div className="custom-prev owl-prev"><i className="fal fa-angle-left"></i></div>
                    <div className="custom-next owl-next"><i className="fal fa-angle-right"></i></div>
                </div>
                <HeroForm/>

                {/* right content */}
                <div className='banner-right-content'>
                    <div>
                        <span className='offer-percentage blink'>Up to 20% off</span>
                    </div>
                    <div>
                    <span className='offer-title'>For 1st time book our service</span>
                    </div>
                                            
                                            
                                        </div>   
            </section>
            {/* <!-- hero end --> */}
        </>
    )
}
