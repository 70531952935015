import React, {useContext} from "react";
import "./style.css";
import { DataContext } from "../../store";

export default function LoadingSpinner() {
  const { state } = useContext(DataContext)
  const { loading } = state
  return (
    <div className="spinner-container">
      {
        loading && <div className="loading"></div>
      }      
    </div>
  );
}