import React from 'react'

function EmbededMap() {
  return (
    <>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7391279021253!2d103.69467231475396!3d1.3326628990280296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da0f8f262bdbad%3A0xe253c89739c3e5a!2s1%20Soon%20Lee%20St%2C%20%2302-06%20Pioneer%20Centre%2C%20Singapore%20627605!5e0!3m2!1sen!2sbd!4v1689233216925!5m2!1sen!2sbd" width="100%" height="450"  allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </>
  )
}

export default EmbededMap