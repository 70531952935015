import React, { useEffect } from 'react'
import MetisMenu from 'metismenujs'

import { Link } from 'react-router-dom'
import { NavMenus } from '../constants/NavMenus';


export default function MobileMenu({MobileMenuTrueFalse}) {
    useEffect(() => {
        new MetisMenu("#metismenu");
    }, []);

    return (
        <>
            <nav className="mobile-nav">
                <ul className="metismenu" id="metismenu">
                    {
                        NavMenus.map((item, index) =>  <li key={index}><Link to={`/${item.path}`} onClick={MobileMenuTrueFalse}>{item.title}</Link></li> )
                    }
                </ul>
            </nav>
        </>
    )
}
