import React from 'react'
import ContactBreadcrumb from './../components/Contact/ContactBreadcrumb';
import ContactGTA from './../components/Contact/ContactGTA';
import EmbededMap from '../components/Contact/EmbededMap';

function Contact() {
  return (
    <>
      <ContactBreadcrumb />
      <ContactGTA />
      <EmbededMap />
    </>
  )
}

export default Contact