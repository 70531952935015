import React from 'react'
import HomeOneHero from '../components/Home/HomeOneHero';
import HomeOneAbout from '../components/Home/HomeOneAbout';
import HomeOneService from '../components/Home/HomeOneService';
import HomeWCU from '../components/Home/HomeWCU';
import HomeOneBrand from '../components/Home/HomeOneBrand';
import HomeOneNews from '../components/Home/HomeOneNews';
import HomeOneCTA from '../components/Home/HomeOneCTA';
import HomeTwoTestimonial from '../components/Home/HomeTwoTestimonial';
import HomeTwoTeam from '../components/Home/HomeTwoTeam';
import HomeTwoCounter from '../components/Home/HomeTwoCounter';
import HomeOneFAQ from './../components/Home/HomeOneFAQ';


function Home() {
  return (
    <>
      <HomeOneHero/>
      
      <HomeOneAbout />
      <HomeOneService />
      <HomeWCU />
      <HomeOneBrand />
      <HomeOneCTA />
      <HomeTwoTestimonial />
      <HomeTwoCounter/>
      <HomeOneFAQ/>
      <HomeTwoTeam />
      {/* <HomeOneNews /> */}
    </>
  )
}

export default Home