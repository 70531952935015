import React from 'react';
import { Link } from 'react-router-dom';

import { ABOUT, COMAPNY_NAME } from '../../constants/ComapnyInfo';
import HomeTwoVideo from './HomeTwoVideo';

export default function HomeOneAbout() {
    return (
        <>
            {/* <!-- about section start --> */}
            <section className="about-area pt-175 pb-80" id="homeAbout">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-xl-6 pr-0">
                            <HomeTwoVideo />
                        </div>
                        <div className="col-xl-6 pl-80">
                            <div className="section-header mb-40">
                                <h4 className="sub-heading mb-10">About Us</h4>
                                <h2 className="section-title mb-35">{COMAPNY_NAME}</h2>
                            </div>
                            <div className="about-lists">
                                <ul>
                                    {ABOUT.split('\n').map((i, index) => {
                                        return <li key={index}><i className="fa fa-check"></i> {i}
                                        </li>
                                    })}


                                </ul>
                                <Link to="about" className="site-btn site-btn__s2 mt-55"><span className="icon icon__black"><i
                                    className="far fa-arrow-right"></i></span> Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- about section end -->    */}
        </>
    )
}
