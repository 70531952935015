import { ACTIONS } from "./Actions";

const reducers=(state,action)=>{
    switch(action.type){
        case ACTIONS.CALL_API: {
            return {
                ...state,
                loading: true,
            };
        }
        case ACTIONS.SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case ACTIONS.ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
}

export default reducers;