import React from 'react'
import VideoPopUp from '../Plugins/VideoPopup'
import { useState } from 'react'

export default function HomeTwoVideo() {
    const [isPopUp, setPopUp] = useState(false)
    return (
        <>
            <div className="video__bg bg_img" style={{ "background": "url(/images/bg/intro-thumb.png)", backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} data-overlay="dark"
                                data-opacity="34">
                                <div className="video-container-wrap">
                                    <a onClick={setPopUp}
                                        data-rel="lightcase:myCollection" data-animation="fadeInLeft" data-delay=".1s"
                                        className="video-link pointer">
                                        <div className="video-play-wrap">
                                            <div className="video-mark">
                                                <div className="wave-pulse wave-pulse-1"></div>
                                                <div className="wave-pulse wave-pulse-2"></div>
                                            </div>
                                            <div className="video-play">
                                                <i className="fa fa-play"></i>
                                            </div>
                                        </div>
                                    </a>
                                    <a onClick={setPopUp} className="video-text pointer"
                                        data-rel="lightcase:myCollection" data-animation="fadeInLeft" data-delay=".1s">Play
                                        Video</a>
                                </div>
                            </div>
                            {isPopUp && <VideoPopUp setShow={() => setPopUp(false)} src={"/videos/intro.mp4"} />}
        </>
    )
}
