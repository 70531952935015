import React from 'react'
import ServiceBreadcrumb from '../components/Service/ServiceBreadcrumb';
import ServiceSection from '../components/Service/ServiceSection';

function Services() {
  return (
    <>
    <ServiceBreadcrumb />
    <ServiceSection/>
    </>
  )
}

export default Services