import React from 'react'
import { Link } from 'react-router-dom'
import Typewriter from 'typewriter-effect';
import { SERVICES } from '../constants/ComapnyInfo'

export default function Footer() {
    return (
        <>

            {/* <!-- footer start --> */}
            <footer className="footer footer__2 pt-120">
                <div className="container">
                    <div className="row mt-none-50 justify-content-center">
                        <div className="col-xl-2 col-lg-3 mt-50">
                            <Link to="/" className="footer__logo">
                                <img src="/images/logo/logo.png" alt="" />
                            </Link>
                        </div>
                        <div className="col-xl-3 col-lg-5 mt-50 pl-45 pr-0">
                            <div className="footer-widget footer-widget__2">
                                <h4 className="widget-title">Services</h4>
                                <ul>
                                    {
                                        SERVICES.slice(0, 6).map((item, index) => (
                                            <li key={index}><Link to="/"><i className="fa fa-angle-right"></i> {item.title}</Link></li>
                                        ))
                                    }


                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-5 mt-50 pl-70 pr-0">
                            <div className="footer-widget footer-widget__2">
                                <h4 className="widget-title">Services</h4>
                                <ul>
                                    {
                                        SERVICES.slice(6, 13).map((item, index) => (
                                            <li key={index}><Link to="/"><i className="fa fa-angle-right"></i> {item.title}</Link></li>
                                        ))
                                    }


                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 mt-50">
                            <div className="footer-widget footer-widget__2">
                                <div className="newslater">
                                    <h4 className="newslater__title">Subscribe to our
                                        Newsletter</h4>
                                    <div className="newslater__form">
                                        <form action="/">
                                            <input type="email" name="email" id="email" placeholder="Enter Email" />
                                            <button type="submit"><i className="far fa-paper-plane"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 mt-50">
                            <h5 className='text-white text-center'>We Are: TMS CLEANING SOLUTIONS PTE.LTD -TMS CONTRACTOR PTE. LTD.-TMS PLATFORM PTE. LTD.</h5>
                            
                            {/* <div className='d-flex justify-content-center'>
                                    <h5 className='text-white'>We Are:</h5> 
                                    <Typewriter
                                        options={{
                                            strings: ['TMS CLEANING SOLUTIONS PTE. LTD', 'TMS CONTRACTOR PTE. LTD', 'TMS PLATFORM PTE. LTD'],
                                            autoStart: true,
                                            loop: true,
                                        }}
                                    />
                            </div> */}
                            
                        </div>
                    </div>
                </div>
                <div className="footer__bottom footer__bottom--2 mt-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 my-auto">
                                <div className="copyright-text">
                                    <p>Copyright &copy; <Link to="/">TMS.</Link> All
                                        Rights Reserved.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="social__links social__links--2">
                                    <Link href="#0"><i className="fab fa-facebook-f"></i></Link>
                                    <Link href="#0"><i className="fab fa-linkedin-in"></i></Link>
                                    <Link href="#0"><i className="fab fa-instagram"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <!-- footer end --> */}
        </>
    )
}
