import React from 'react'
import AboutBreadcrumb from './../components/About/AboutBreadcrumb';
import AboutSection from './../components/About/AboutSection';
import HomeTwoTeam from './../components/Home/HomeTwoTeam';


function About() {
  return (
    <>
    <AboutBreadcrumb/>
            <AboutSection/>
            <HomeTwoTeam/>
            
    </>
  )
}

export default About