import axios from "axios";
import { toast } from 'react-toastify';
import { api_url } from "./baseUrl";

export const axiosInterceptor = axios.create({
  baseURL: api_url,
  headers: {
    Accept: "application/json",
    "app-key": "a1-V1QiLCJhbUzI1NiJ9}eyJhnRpIjoiYqQ"
  }
});

axiosInterceptor.interceptors.response.use(
  response => {
    return {
      success: true,
      error: '',
      data: response.data,
      message: response.data.message
    };
  },
  error => {
    toast.error(getErrorMessage(error), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    return {
      success: false,
      error: error.response.status === 400 ? error.response.data : '',
      data: null
    };

  });

function getErrorMessage(error) {
  if (error.response.status === 404) {
    return `${error.response.status}, ${error.response.data.message === undefined ? 'Not Found' : error.response.data.message}`
  }

  else if (error.response.status === 400) {
    return `${error.response.status}, ${error.response.data.message === undefined ? 'Validation Error' : error.response.data.message}`
  }

  else if (error.response.status === 401) {
    return `${error.response.status}, ${error.response.data.message === undefined ? 'Unauthorized' : error.response.data.message}`
  }

  else if (error.response.status === 500) {
    return `${error.response.status}, ${error.response.data.message === undefined ? 'Internal Server Error' : error.response.data.message}`
  }

  else {
    return `${error.response.status}, ${error.response.data.message === undefined ? 'Unknown Error' : error.response.data.message}`
  }
}
export default axiosInterceptor;