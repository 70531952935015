import React, { useState } from 'react'

export default function HomeOneFAQ() {
    const [activeDefault, setActiveDefault] = useState(0);
    const FAQ = [
        {
            q: "What is the Minimum work hour for service ?",
            a:"There is no Minimum hour for our service as long as the job is done according to requirements"
        },
        {
            q: "What is Job Scope for Cleaning?",
            a:"We are professional house cleaning contractor with trained crew . Except External anything we are going to clean everything in a house"
        },
        {
            q: "How do i booking a Slot or a Service from TMS Home Services?",
            a:"1. Look Upon the front page Get a quote section Submit a request . 2. Click Contact Us section and Pick Admin or Sales contact for direct conversation."
        },
        {
            q: "Does the service included tools & machinary?",
            a:"Yes. We will provide Manpower, Tools, Equipment to complete our services"
        }
        
    ]
    return (
        <>
            {/* <!-- faq section start --> */}
            <section className="faq-area pt-80 pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="section-header mb-25">
                                <h4 className="sub-heading mb-15">Common Question</h4>
                                <h2 className="section-title">Asked Questions</h2>
                            </div>
                            <div className="accordion faqs" id="accordionFaq">
                                {FAQ.map((d, i) => (
                                    <div className="card" key={i}>
                                        <div onClick={() => setActiveDefault(activeDefault === i ? -1 : i)} className="card__header" id="heading1">
                                            <h5 className="mb-0 title">
                                                <button onClick={() => setActiveDefault(activeDefault === i ? -1 : i)} className={activeDefault === i ? "btn btn-link collapsed" : "btn btn-link"} type="button" data-toggle="collapse"
                                                    data-target="#collapse1" aria-expanded={activeDefault === i ?"true" :"false"} aria-controls="collapse1">
                                                   {d.q}
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse1" className={`collapse ${activeDefault === i ? "show" : ""}`} aria-labelledby="heading1" data-parent="#accordionFaq">
                                            <div className="card__body">
                                                <p>{d.a}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="faq-bg">
                                <img src="/images/bg/faq-bg-1.jpeg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- faq section end -->    */}
        </>
    )
}
