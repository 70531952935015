import React from 'react'

function ServiceItem({item}) {
    return (
        <div className="col-xl-6 col-lg-6 mt-50">
            <div className="service-item d-flex">
                <div className={`service-icon`}>
                    <img src={item.imgPath} alt={item.title} />
                </div>
                <div className="service-item__content" style={{flex: 1}}>
                    <h4 className="service-item__title">{item.title}</h4>
                    <p>{item.subTitle}</p>
                </div>
            </div>
        </div>
    )
}

export default ServiceItem