import React from 'react'
import { ADDRESS, CONTACT_1, CONTACT_2, CONTACT_3, EMAIL } from '../../constants/ComapnyInfo'
import { Link } from 'react-router-dom'

export default function ContactGTA() {
    return (
        <>
            {/* <!-- gta section start --> */}
            <section className="gta-area gta-area__2 pt-125 pb-120">
                <div className="container">
                    <div className="row gx-lg-0 gy-4">
                        <div className="col-xl-4">
                            <div className='info-container d-flex flex-column align-items-center justify-content-center'>
                                <div className='info-item d-flex'>
                                    <i className="bi bi-geo-alt flex-shrink-0"></i>
                                    <div>
                                        <h4>Location:</h4>
                                        {ADDRESS.split('\n').map((i, index) => {
                                            return <p key={index}>{i}</p>
                                        })}
                                    </div>
                                </div>

                                <div className="info-item d-flex">
                                    <i className="bi bi-envelope flex-shrink-0"></i>
                                    <div>
                                        <h4>Email:</h4>
                                        <p><strong> <Link to={`mailto:${EMAIL}`}>{EMAIL}</Link></strong></p>
                                    </div>
                                </div>

                                <div className="info-item d-flex">
                                    <i className="bi bi-headset flex-shrink-0"></i>
                                    <div>
                                        <h4>Contact:</h4>
                                        <p><strong> <Link to={`tel:${CONTACT_1}`}>{CONTACT_1} (Admin)</Link></strong></p>
                                        <p><strong> <Link to={`tel:${CONTACT_2}`}>{CONTACT_2} </Link></strong></p>
                                        <p><strong> <Link to={`tel:${CONTACT_3}`}>{CONTACT_3} (Operation & Sales)</Link></strong></p>
                                    </div>
                                </div>

                                <div className="info-item d-flex">
                                    <i className="bi bi-clock flex-shrink-0"></i>
                                    <div>
                                        <h4>Service Hours:</h4>
                                        <p>24/7</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8">
                            <div className='form-container'>
                                <div className="section-header mb-50">
                                    <h4 className="sub-heading sub-heading__2 mb-15">Send Message
                                        <span><img src="/images/shape/heading-shape-4.png" className="mr-10" alt="" /></span>
                                    </h4>
                                    <h2 className="section-title section-title__2">Get in Touch</h2>
                                </div>
                                <div className="contact-form">
                                    <form action="#" method="POST" id="contact-form">
                                        <div className="form-group mt-25">
                                            <input type="text" name="name" id="name" placeholder="Your Name" />
                                        </div>
                                        <div className="form-group mt-25">
                                            <input type="email" name="email" id="mail" placeholder="Email Address" />
                                        </div>
                                        <div className="form-group mt-25">
                                            <textarea name="message" id="message" placeholder="Your Message"></textarea>
                                        </div>
                                        <button type="submit" className="site-btn site-btn__2 mt-15"><span className="icon icon__black"><i
                                            className="far fa-arrow-right"></i></span> Contact us</button>
                                        <p className="ajax-response"></p>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- gta section end -->    */}
        </>
    )
}
