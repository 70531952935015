import React from 'react'
import { SERVICES } from '../../constants/ComapnyInfo'
import ServiceItem from '../Home/ServiceItem'

export default function ServiceSection() {
    return (
        <>
            {/* <!-- service section start --> */}
            <section className="service-area pt-75 pb-75">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 text-center">
                            <div className="section-header mb-75">
                                <h4 className="sub-heading mb-25">
                                    <span><img src="/images/shape/heading-shape-1.png" className="mr-10" alt="" /></span>
                                    Our Services
                                    <span><img src="/images/shape/heading-shape-2.png" className="ml-10" alt="" /></span>
                                </h4>
                                <h2 className="section-title">All Services We Offer</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-none-50">
                        {
                            SERVICES.map((item, index) => (
                                <ServiceItem item={item} key={index} />
                            ))
                        }
                    </div>
                </div>
            </section>
            {/* <!-- service section end -->    */}
        </>
    )
}
