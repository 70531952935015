import React, { createContext, useReducer } from "react";
import reducers from "./Reducers";

export const DataContext = createContext();

export const Store = ({ children }) => {
    const initialState = {
        loading: false,
        error: null,
    };

    const [state, dispatch] = useReducer(reducers, initialState);

    return (
        <DataContext.Provider value={{ state, dispatch }}>
            {children}
        </DataContext.Provider>
    )
}