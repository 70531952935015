import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from './MobileMenu';
import { NavMenus } from '../constants/NavMenus';
import { CONTACT_2, EMAIL } from './../constants/ComapnyInfo';

export default function Header1() {
    const [isMobileMenu, setMobileMenu] = useState(false);
    const MobileMenuTrueFalse = () => setMobileMenu(!isMobileMenu)

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 100);
        });
    }, [scroll]);

    return (
        <>
            <div className='header'>
                <div className="header__top">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 p-0">
                                <ul className="header__info">
                                    <li><Link to={`tel:${CONTACT_2}`}><i className="fa fa-phone-volume"></i> {CONTACT_2} </Link>
                                    </li>
                                    <li><Link to={`mailto:${EMAIL}`}><i className="fa fa-envelope-open"></i>
                                        {EMAIL}</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-6 col-md-12 my-auto p-0">
                                <div className="social__links">
                                    <Link to="/"><i className="fab fa-facebook-f"></i></Link>
                                    <Link to="/"><i className="fab fa-linkedin-in"></i></Link>
                                    <Link to="/"><i className="fab fa-instagram"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`header__bottom ${scroll ? "sticky-header animated fadeInDown" : ""}`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="navarea">
                                    <Link to="/" className="site-logo">
                                        <img src="/images/logo/logo.png" alt="LOGO" />
                                    </Link>
                                    <div className="mainmenu d-none d-lg-block">
                                        <nav id="mobile-menu">
                                            <ul>                                                
                                                {
                                                    NavMenus.map((item, index) => <li key={index}><Link to={`/${item.path}`}>{item.title}</Link></li>)
                                                }
                                            </ul>
                                        </nav>
                                    </div>

                                    <div className="mobile-menu mean-container d-block d-lg-none ">
                                        <div className="mean-bar">
                                            <Link onClick={MobileMenuTrueFalse} href="#nav" className={isMobileMenu ? "meanmenu-reveal d-none" : "meanmenu-reveal"} >
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </Link>
                                            <Link onClick={MobileMenuTrueFalse} href="#nav" className={isMobileMenu ? "meanmenu-reveal" : "meanmenu-reveal d-none"} style={{ "right": "0px", "left": "auto", "textAlign": "center", "textIndent": "0px", "fontSize": "18px" }}>
                                                X
                                            </Link>
                                        </div>
                                        {isMobileMenu &&
                                            <MobileMenu MobileMenuTrueFalse = {MobileMenuTrueFalse} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
