import React, {useState, useContext} from 'react'
import { toast } from 'react-toastify';
import moment from 'moment';
import { SERVICES } from '../../constants/ComapnyInfo'
import { COLOR_1 } from './../../constants/Colors';
import { DataContext } from '../../store';
import { ACTIONS } from '../../store/Actions';
import axiosInterceptor from '../../utils/axiosInterceptor';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

function HeroForm() {
    const { dispatch } = useContext(DataContext);  
    
    const [errors, setErrors] = useState([])

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [serviceId, setServiceId] = useState('');    
    const [serviceName, setServiceName] = useState('');
    const [dateOfVisit, setDateOfVisit] = useState('');
    const [description, setDescription] = useState('');
    const [phone, setPhone] = useState('');

    const clearAll = () =>{
        setFirstName('');
        setLastName('');
        setServiceId('');
        setServiceName('');
        setDateOfVisit('');
        setDescription('');
        setPhone('');
    }

    const handleSubmit =async()=>{
        setErrors([])
        let postData = {
            first_name: firstName,
            last_name: lastName,
            service_id: serviceId,
            service_name: serviceName,
            date_of_visit: dateOfVisit ? moment(dateOfVisit).format("YYYY-MM-DD") : '',
            description,
            phone
        }

        dispatch({ type: ACTIONS.CALL_API });
        let response = await axiosInterceptor.post('get-quote-request', postData);
        console.log(JSON.stringify(response));
        if (!response.success) { 
            if(response.error.errors){
                setErrors(response.error.errors)
            }           
            dispatch({ type: ACTIONS.ERROR, error: response.error });
            return;
        }        
        dispatch({ type: ACTIONS.SUCCESS });
        toast.success(response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });

        clearAll();
        
    }

    return (
        <section className="hero-form-area">
            <div className="hero-form">
                <div className='container'>
                    <div className='hero-form-wrapper'>
                        <div className='row'>
                            <div className='col-xl-4 col-sm-12 '>
                                <div className='align-items-center d-flex justify-content-center theme-bg h-100'>
                                    <h2 className='get-a-quote-title'>Get a Quote</h2>
                                </div>
                            </div>
                            <div className='col-xl-8 col-sm-12 px-4 py-4'>
                                <div className='row'>
                                    <div className='col-xl-4 col-sm-12 mb-3'>
                                        <input className={`form-control ${errors.first_name ? 'is-invalid' : '' }`} placeholder='First Name' onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                                    </div>
                                    <div className='col-xl-4 col-sm-12 mb-3'>
                                        <input className={`form-control ${errors.last_name ? 'is-invalid' : '' }`} placeholder='Last Name' onChange={(e) => setLastName(e.target.value)} value={lastName} />
                                    </div>
                                    <div className='col-xl-4 col-sm-12 mb-3'>
                                        <input className={`form-control ${errors.phone ? 'is-invalid' : '' }`} placeholder='Phone No' onChange={(e) => setPhone(e.target.value)} value={phone}/>
                                    </div>

                                    <div className='col-xl-8 col-sm-12 mb-3'>
                                        <select className={`form-control ${errors.service_id ? 'is-invalid' : '' }`} onChange={(e) => {setServiceName(e.target.options[e.target.selectedIndex].text); setServiceId(e.target.value)}} value={serviceId}>
                                            <option value="">--Select Service--</option>
                                            {
                                                SERVICES.map((item, index) => (
                                                    <option value={item.id} key={index}>{item.title}</option>
                                                ))
                                            }

                                        </select>
                                    </div>

                                    <div className='col-xl-4 col-sm-12 mb-3'>
                                        {/* <input className={`form-control ${errors.date_of_visit ? 'is-invalid' : '' }`} placeholder='Date Of Visit' onChange={(e) => setDateOfVisit(e.target.value)} value={dateOfVisit} /> */}
                                    
                                        <DatePicker     
                                            selected={dateOfVisit} 
                                            onChange={(date) => setDateOfVisit(date)} 
                                            className={`form-control w-100 ${errors.date_of_visit ? 'is-invalid' : '' }`}
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText = "Date of Visit"
                                        />
                                    </div>

                                    <div className='col-xl-12 col-sm-12 mb-3'>
                                        <textarea className={`form-control ${errors.description ? 'is-invalid' : '' }`} placeholder='Details' onChange={(e) => setDescription(e.target.value)} value={description} ></textarea>
                                    </div>

                                    <div className='col-xl-12 col-sm-12 text-center'>
                                        <button 
                                            type='button' 
                                            style={{margin: 'auto', backgroundColor: COLOR_1, color: 'white', padding: '5px 10px'}}
                                            onClick={() => handleSubmit()}
                                        >
                                            <i className="fa fa-paper-plane mr-2"></i>Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    )
}

export default HeroForm