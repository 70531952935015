import React from 'react'
import { ABOUT } from '../../constants/ComapnyInfo'

export default function AboutSection() {
    return (
        <>
            {/* <!-- about section start --> */}
            <section className="about-area pt-125 pb-125">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-xl-5">
                            <div className="about__bg about__bg--2 mt-75">
                                <div className="big-thumb">
                                    <img src="/images/logo/logo-wh-bg.jpeg" alt="" />
                                </div>
                                <div className="mid-thumb position-absulate">
                                    <img src="/images/about/about-bg-2.png" alt="" />
                                </div>
                                <div className="small-thumb position-absulate">
                                    <img src="/images/about/about-bg-3.png" alt="" />
                                </div>
                                <span className="circle-shape position-absulate"><img src="/images/shape/border-shape-2.png" alt="" /></span>
                                <span className="patternt-shape position-absulate"><img src="/images/shape/about-shape-1.png" alt="" /></span>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="section-header section-header__2 mb-40">
                                <h4 className="sub-heading sub-heading__2 mb-10">About Us <span><img
                                    src="/images/shape/heading-shape-4.png" className="ml-10" alt="" /></span></h4>
                                
                                </div>
                            <div className="row mt-none-40">
                            {ABOUT.split('\n').map((i, index) => (
                                <div className="col-xl-6 mt-40" key={index}>
                                <div className="ab__box">
                                    <p>
                                        {i}
                                    </p>
                                </div>
                            </div>
                            ))}
                                

                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- about section end --> */}
        </>
    )
}
