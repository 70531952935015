import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

export default function HomeTwoTestimonial() {
    return (
        <>
            {/* <!-- testimonial area start --> */}
            <div className="testimonial-area testimonial-area__2 pt-130 pb-130 grey-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="testimonial__2 owl-carousel text-center position-relative">
                                <Swiper
                                modules={[Autoplay, Navigation]}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                  }}
                                  
                                  
                                    spaceBetween={2}
                                    slidesPerView={1}
                                    
                                    className="custom-class"
                                    navigation={{
                                        prevEl: ".custom-prev",
                                        nextEl: ".custom-next",
                                    }}
                                    // scrollbar={{ draggable: true }}
                                >

                                    <SwiperSlide>
                                        <div className="testimonial-item">
                                            <div className="testimonial__2--icon mb-70">
                                                <img src="/images/icons/t-quote-2.png" alt="" />
                                            </div>
                                            <div className="testimonial__2--content">
                                                <p>Hello Clients!  Currently we are providing A full package of  Home Services. Many of our Clients looking us about other services in their apartment . Thus we are now prepare and serve all HOME SERVICES in a house. The Reason Why All woners,landlords,clients,agents are direct Looking for us & engage us for overall home service. 
Now TMS is a trusted  brand in Singapore  coz of providing one stop Home Service.</p>
                                            </div>
                                            <div className="testimonial__2--author mt-55">
                                                <div className="thumb">
                                                    <img src="/images/other/author-2.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h4 className="name">ZIHAN</h4>
                                                    <span className="designation">Co-Director</span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="testimonial-item">
                                            <div className="testimonial__2--icon mb-70">
                                                <img src="/images/icons/t-quote-2.png" alt="" />
                                            </div>
                                            <div className="testimonial__2--content">
                                                <p>Welcome to SG House Cleaning, your trusted partner for professional and reliable house cleaning services in Singapore. We are a team of experienced cleaners dedicated to providing top-notch cleaning services to meet your unique needs. Whether you need a one-time deep clean or a regular weekly or monthly cleaning service, we are here to help. Our commitment to customer satisfaction, attention to detail, and techniques sets us apart from other cleaning services. Explore our website to learn more about our services, pricing, and testimonials from satisfied customers. Contact us today to schedule your next cleaning appointment!</p>
                                            </div>
                                            <div className="testimonial__2--author mt-55">
                                                <div className="thumb">
                                                    <img src="/images/other/author-2.png" alt="" />
                                                </div>
                                                <div className="content">
                                                    <h4 className="name">Mohamed Jamal Uddin</h4>
                                                    <span className="designation">Director</span>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                                <div className="owl-nav">
                                    <div className="owl-prev custom-prev"><i className="fal fa-long-arrow-left"></i></div>
                                    <div className="owl-next custom-next"><i className="fal fa-long-arrow-right"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- testimonial area end -->    */}
        </>
    )
}
