export const COMAPNY_NAME = "TMS HOME SERVICES";
export const CONTACT_1 = "97241605";
export const CONTACT_2 = "63391415";
export const CONTACT_3 = "91749279";
export const EMAIL = "tmsgroup50@gmail.com";
export const ADDRESS = `1 Soon Lee Street #02-06\nSingapore 627605`;
export const SERVICES = [
    {
        id: 1,
        title: 'Professional Cleaning Service',
        subTitle: 'We are Trained, Experienced professional house cleaning company in Singapore.Entire Singapore Know us for Affordable price & Quality of our work.',
        imgPath: '/images/services/Professional-Cleaning-Service.jpeg'
    },
    {
        id: 2,
        title: 'Pipe Leakage Repair Service',
        subTitle: 'Worry For Chock & Leackage of water pipe in ur home? Dont Worry we r here to serve you 24/7 . Any emergency service  TMS Home Service is right choice for u',
        imgPath: '/images/services/Pipe-Leakage-Repair-Service.jpeg'
    },
    {
        id: 3,
        title: 'Marble Polishing and Floor Scrubbing',
        subTitle: 'Did ur Marble floor get Blur ? Not Shining?  TMS HOME SERVICE is here to Sparkle your house by Polishing Marble Floor. ',
        imgPath: '/images/services/Marble-Polishing-and-Floor-Scrubbing.jpeg'
    },
    {
        id: 4,
        title: 'Waterproofing and coating',
        subTitle: 'We are Specilaize In waterproffing and coating . we are doing waterproffing work since 15 years. we have strong  team to do our job Smooth and satisfied.',
        imgPath: '/images/services/Water-proofing-and-coating.jpeg'
    },
    {
        id: 5,
        title: 'Home Painting',
        subTitle: 'We have 100 over Painting Sites there our Professional painter will perform their best. Our Home Painting Quote is cheaper than any other painting contractor .',
        imgPath: '/images/services/Home-Painting.jpeg'
    },
    {
        id: 6,
        title: 'CCTV Installation and Repair',
        subTitle: 'We are direct contractor for Installation or servicing All cctv setup.',
        imgPath: '/images/services/CCTV-Installation.jpeg'
    },
    {
        id: 7,
        title: 'Full House Renovation',
        subTitle: 'Looking for Interrior Designer to design ur dream house? TMS Home Service/TMS Contractor will be a trusted renovation company to you. we are Renovation & Design specialist.',
        imgPath: '/images/services/Full-House-Renovation.jpeg'
    },
    {
        id: 8,
        title: 'Hacking and Demolition Works',
        subTitle: 'If you want to rebuild ur toilet,rooms,office and need hacker to hack and dispose the derbis Contact us for More info .',
        imgPath: '/images/services/Hacking-and-Demolition-Works.jpeg'
    },
    {
        id: 9,
        title: 'Tiles Restoration Works',
        subTitle: 'we have experienced Direct Tiler, trained and completed few huge tiling project so far. Very Comfortable price in your budget.',
        imgPath: '/images/services/Tiles-Restoration-Works.jpeg'
    },
    {
        id: 10,
        title: 'Handyman Services',
        subTitle: 'We serve 24/7 Handyman services. we ready to serve you during  your emmergency or non emmergency. Just a Call away',
        imgPath: '/images/services/Handyman.jpeg'
    },
    {
        id: 11,
        title: 'Vinyl Flooring installation',
        subTitle: 'Want to install Modern, attractive,smooth Floor in your Apartment ? this is TMS we will do for you in a best and affordable prices. We have Vinyl, Paraquet, Timber flooring and so on.  Contact us for Learn More',
        imgPath: '/images/services/s11.jpeg'
    },
    {
        id: 12,
        title: 'Electrical Works',
        subTitle: 'We Have high Skill Plumber and Electritian . IF need electric or Plumbing Contractor Just contact us ,describe.',
        imgPath: '/images/services/s12.jpeg'
    },
    {
        id: 13,
        title: 'Plumbing Works',
        subTitle: ' If you looking for professional Plumber for your house TMS  will provide you best plumber in Singapore. All our Plumber has completed their plumbing work as a satisfectory method & touchup less job. Call us for best quote and discuss about your expectations. We will do our best on our Plumbing Service.',
        imgPath: '/images/services/plumbing.jpeg'
    },
    {
        id: 14,
        title: 'Others Service',
        subTitle: '',
        imgPath: '/images/services/others-service.jpg'
    },
]

export const PARTNERS = [
    {
        id: 1,
        title: 'BCA',
        imgPath: '/images/brand/BCA.jpeg'
    },
    {
        id: 2,
        title: 'NEA',
        imgPath: '/images/brand/NEA.jpeg'
    },
    {
        id: 3,
        title: 'ISO',
        imgPath: '/images/brand/ISO.jpeg'
    },
    {
        id: 4,
        title: 'Safe Star',
        imgPath: '/images/brand/ss.jpeg'
    }
]

export const ABOUT = "Specializes in the home services related design, re-build, renovation, handyman, construction all over home services in one place. \nOur focus is to provide energy efficient, durable and serviceable systems that produce the absolute best quality service available! To ensure high quality workmanship, our staff consists of specialists in the refrigeration, electrical and welding trades. \nWith 40 years in the business and hundreds of completed projects, we have grown from a basic refrigeration service company to a world leader in the home service.";