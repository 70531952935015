export const NavMenus = [
    {
        title: 'Home',
        path: ''
    },
    {
        title: 'Services',
        path: 'services'
    },
    {
        title: 'About',
        path: 'about'
    },
    {
        title: 'Contact',
        path: 'contact'
    }
]